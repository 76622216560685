import { React } from 'react';

const MessageNoResult = () => {
  return (
    <div>
      <p>無相關搜尋結果</p>
    </div>
  );
};

export default MessageNoResult;
